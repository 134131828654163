import { getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { Button } from '@conkoa/ui/button'
import { cn } from '@conkoa/ui/utils/misc'
import type { ActionFunctionArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useFetcher, useFetchers } from '@remix-run/react'
import { MonitorIcon, MoonIcon, SunIcon } from 'lucide-react'
import { z } from 'zod'
import { ErrorList } from '~/components/forms'
import { useHints } from '~/utils/client-hints'
import { invariantResponse } from '~/utils/misc'
import { useRequestInfo } from '~/utils/request-info'
import type { Theme } from '~/utils/theme.server'
import { setTheme } from '~/utils/theme.server'

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark']),
})

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema,
  })

  invariantResponse(submission.status === 'success', 'Invalid form submission')

  const { theme } = submission.value

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) },
  }
  return json({ result: submission.reply() }, responseInit)
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints()
  const requestInfo = useRequestInfo()
  const optimisticMode = useOptimisticThemeMode()
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode
  }
  return requestInfo.userPrefs.theme ?? hints.theme
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers()
  const themeFetcher = fetchers.find((f) => f.formAction === '/')

  if (themeFetcher?.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema,
    })

    if (submission.status === 'success') {
      return submission.value.theme
    }
  }
}

export function ThemeSwitch({
  userPreference,
  id,
  className,
}: {
  userPreference?: Theme | null
  id: string
  className?: string
}) {
  const fetcher = useFetcher<typeof action>()

  const [form] = useForm({
    id: `${id}-theme-switch-form`,
    lastResult: fetcher.data?.result,
  })

  const optimisticMode = useOptimisticThemeMode()
  const mode = optimisticMode ?? userPreference ?? 'system'

  const sectionId = `${id}-theme-switch`

  return (
    <section
      aria-describedby={sectionId}
      className={cn('flex items-center gap-1', className)}
    >
      <p id={sectionId} className="sr-only">
        Change Theme
      </p>
      <fetcher.Form
        method="POST"
        action="/api/theme-change"
        {...getFormProps(form)}
      >
        <div className="flex gap-3">
          <Button
            variant="outline"
            size="icon"
            role="radio"
            aria-checked={mode === 'light'}
            type="submit"
            name="theme"
            value="light"
            className={cn(
              'flex h-7 w-7 cursor-pointer items-center justify-center rounded-full',
              mode === 'light'
                ? 'border-primary/50 bg-card text-foreground'
                : 'text-muted-foreground',
            )}
          >
            <span className="sr-only">Light</span>
            <SunIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            role="radio"
            aria-checked={mode === 'dark'}
            type="submit"
            name="theme"
            value="dark"
            className={cn(
              'flex h-7 w-7 cursor-pointer items-center justify-center rounded-full',
              mode === 'dark'
                ? 'border-primary/50 bg-card text-foreground'
                : 'text-muted-foreground',
            )}
          >
            <span className="sr-only">Dark</span>
            <MoonIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            role="radio"
            aria-checked={mode === 'system'}
            type="submit"
            name="theme"
            value="system"
            className={cn(
              'flex h-7 w-7 cursor-pointer items-center justify-center rounded-full',
              mode === 'system'
                ? 'border-primary/50 bg-card text-foreground'
                : 'text-muted-foreground',
            )}
          >
            <span className="sr-only">System</span>
            <MonitorIcon className="h-4 w-4" />
          </Button>
        </div>
        <ErrorList errors={form.errors} id={form.errorId} />
      </fetcher.Form>
    </section>
  )
}
